import React from 'react';
import PropTypes from 'prop-types';
import { PARTNER_OFFER_ADDRESS_LOOKUP } from 'client/site-modules/shared/constants/appraisal/appraisal';
import { FindVinByAddress } from 'site-modules/shared/components/find-vin-by-address/find-vin-by-address';

export const AddressTab = ({ isLandingPage, creativeId, ...restProps }) => (
  <div className="address-tab">
    <FindVinByAddress
      {...restProps}
      creativeId={creativeId}
      hasEscapeHatch={false}
      isAddressTab
      isLandingPage={isLandingPage}
    />
  </div>
);

AddressTab.propTypes = {
  creativeId: PropTypes.string,
  isLandingPage: PropTypes.bool,
};

AddressTab.defaultProps = {
  creativeId: PARTNER_OFFER_ADDRESS_LOOKUP,
  isLandingPage: true,
};
