export const TAB_ID = {
  LICENSE_PLATE: 'LICENSE_PLATE',
  VIN: 'VIN',
  ADDRESS: 'ADDRESS',
};

export const TAB_VIN_ERROR_ID = {
  SQUISH_VIN: 'SQUISH_VIN',
  VIN_ELIGIBILITY: 'VIN_ELIGIBILITY',
};

export const APPRAISAL_STORAGE_KEYS = {
  LICENSE_PLATE: 'LICENSE_PLATE',
  STATE_CODE: 'STATE_CODE',
  VIN: 'VIN',
  APPRAISAL_TABS_ACTIVE_TAB: 'APPRAISAL_TABS_ACTIVE_TAB',
};
